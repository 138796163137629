@tailwind base;
@tailwind components;
@tailwind utilities;

div#rita_chatbot {
  z-index: 9999 !important;
}

div#rita_chatbot iframe#rita_chatbot_frame {
  border-radius: 20px;
}

div#rita_chatbot iframe#rita_chatbot_frame[style*='width: 100px'] {
  width: 72px !important;
  height: 72px !important;
}

@layer base {
  html {
    font-family: theme(fontFamily.body);
  }
}

@layer components {
  .indicator::before {
    position: absolute;
    left: 0px;
    box-sizing: border-box;
    display: block;
    height: 0px;
    width: 0px;
    flex-shrink: 0;
    border-width: 11px;
    border-right-width: 0px;
    border-style: solid;
    border-color: transparent;
    border-left-color: currentColor;
    pointer-events: none;
    content: '';
  }

  .formatted strong {
    font-weight: theme(fontWeight.semibold);
    font-family: theme(fontFamily.display);
    letter-spacing: theme(letterSpacing.wider);
  }

  .formatted.font-display strong {
    font-weight: theme(fontWeight.semibold);
    font-family: theme(fontFamily.sans);
    letter-spacing: theme(letterSpacing.normal);
    white-space: nowrap;
  }
}

@layer utilities {
  .scroll-w-0 {
    scrollbar-width: 0;
  }

  .scroll-w-0::-webkit-scrollbar {
    width: 0;
  }

  .forced-light {
    -webkit-box-shadow: 0 0 0 30px rgb(250 250 250 / var(--tw-bg-opacity)) inset !important;
    -webkit-text-fill-color: rgb(34 31 32 / var(--tw-text-opacity)) !important;
  }

  .forced-dark {
    -webkit-box-shadow: 0 0 0 30px rgb(34 31 32 / var(--tw-text-opacity)) inset !important;
    -webkit-text-fill-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
  }

  .clip-full {
    clip-path: inset(0);
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 0 0% 63.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 0 0% 14.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  /* body {
    @apply bg-background text-foreground;
  } */
}
